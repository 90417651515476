// /////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2021 Autodesk, Inc. All rights reserved.
//
//                     ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
// /////////////////////////////////////////////////////////////////////

import {
  DetailedUsage,
  DownloadDto,
  JobRunItemStatusType,
  JobRunStatusType,
  StartUsageDownloadResult,
  UsageSortType,
  UsageSummary
} from "../clients/Classes";
import {ClientProvider} from "../clients/ClientProvider";
import {ServiceBase} from "./ServiceBase";
import {IClient} from "../clients/Client";
import {IV2Client} from "../clients/V2Client";
import {DetailedUsageRequest} from "../clients/V2Classes";

export class UsageDataService extends ServiceBase {
  private _client: IClient = ClientProvider.Client;
  private _v2Client: IV2Client = ClientProvider.V2Client;

  public StartUsageExport(
    request: DetailedUsageRequest
  ): Promise<StartUsageDownloadResult> {
    return this._v2Client.startDetailedDownload(request);
  }

  public GetUsageDownload(id: string): Promise<DownloadDto> {
    return this.TryPromiseWithCatch(() =>
      this._client.getUsageDownload(id)
    );
  }

  public GetUsageSummary(
    statuses?: JobRunStatusType[],
    customerIds?: string[],
    userIds?: string[],
    jobIds?: string[],
    startTime?: Date,
    endTime?: Date,
    paginationToken?: string | undefined,
    limit?: number | undefined
  ): Promise<UsageSummary> {

    return this.TryPromiseWithCatch(() =>
      this._client.getUsageSummary(statuses, false, limit, paginationToken, customerIds, userIds, jobIds, startTime, endTime)
    );
  }

  public GetUsageDetail(statuses?: JobRunItemStatusType[],
                        customerIds?: string[],
                        userIds?: string[],
                        jobIds?: string[],
                        startTime?: Date,
                        endTime?: Date,
                        sort?: UsageSortType,
                        sortAscending?: boolean,
                        paginationToken?: string,
                        paginationLimit?: number): Promise<DetailedUsage> {

    return this.TryPromiseWithCatch(() =>
      this._client.getDetailedUsage(
        statuses,
        paginationLimit,
        paginationToken,
        customerIds,
        userIds,
        jobIds,
        startTime,
        endTime,
        sort,
        sortAscending)
    );
  }
}
