////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import React, {useState} from 'react';
import Selector from "./Selector";
import {FileDestinationNamingType} from "../clients/Classes";
import {DefaultItem} from "@adsk/alloy-react-dropdown";
import {BlueButton, FlexRow, FlexRowCentered} from "../CommonStyledComponents";
import Theme from "@adsk/alloy-react-theme";
import Checkbox, {CheckboxState} from "@adsk/alloy-react-checkbox";
import {InfoPopover} from "@adsk/alloy-react-tooltip";
import Modal from "@adsk/alloy-react-modal";
import zipExample from '../assets/ZipExample.png';
import {ClientProvider} from "../clients/ClientProvider";
import {ROLE_ID_COPY_ZIP} from "../Constants";

const authService = ClientProvider.AuthService;

const SettingsOptions = (
  {
    namingType,
    emailOnCompletion,
    attachCsv,
    maintainFolderStructure,
    unzipRvt,
    onNamingChanged,
    onEmailChanged,
    onAttachCsvChanged,
    onMaintainFolderStructureChanged,
    onUnzipChanged
  }: {
    namingType: FileDestinationNamingType | undefined,
    emailOnCompletion: boolean,
    attachCsv: boolean,
    maintainFolderStructure: boolean,
    unzipRvt: boolean,
    onNamingChanged?: (name: FileDestinationNamingType | undefined) => void
    onEmailChanged?: (email: boolean) => void,
    onAttachCsvChanged?: (attach: boolean) => void,
    onMaintainFolderStructureChanged?: (maintain: boolean) => void,
    onUnzipChanged?: (unzip: boolean) => void
  }) => {
  const [naming, setNaming] = useState(namingType);
  const [email, setEmail] = useState(emailOnCompletion);
  const [csv, setCsv] = useState(attachCsv);
  const [maintainStructure, setMaintainStructure] = useState(maintainFolderStructure);
  const [unzip, setUnzip] = useState(unzipRvt);
  const [showUnzipWarning, setShowUnzipWarning] = useState(false);

  const options: DefaultItem[] = [
    {value: FileDestinationNamingType.Overwrite, label: 'Overwrite Existing Files'},
    {value: FileDestinationNamingType.AppendTimestamp, label: 'Append Time Stamp'},
  ];

  function namingChanged(value: DefaultItem | null | undefined): void {
    const newVal = value == null
      ? FileDestinationNamingType.None
      : value.value as FileDestinationNamingType;
    setNaming(newVal);
    if (onNamingChanged) {
      onNamingChanged(newVal);
    }
  }

  function emailChanged(state: CheckboxState): void {
    const newVal = state === true;
    setEmail(newVal);
    if(onEmailChanged){
      onEmailChanged(newVal);
    }
  }

  function csvChanged(state: CheckboxState): void {
    const newVal = state === true;
    setCsv(newVal);
    if(onAttachCsvChanged){
      onAttachCsvChanged(newVal);
    }
  }

  function maintainFileStructureChanged(state: CheckboxState): void {
    const newVal = state === true;
    setMaintainStructure(newVal);
    if(onMaintainFolderStructureChanged){
      onMaintainFolderStructureChanged(newVal);
    }
  }

  function unzipChanging(state: CheckboxState): void {
    if (state === true) {
      setShowUnzipWarning(true);
    } else {
      setUnzip(false);
      if(onUnzipChanged){
        onUnzipChanged(false);
      }
    }
  }

  function unzipWarningAccepted(): void {
    setShowUnzipWarning(false);
    setUnzip(true);
    if(onUnzipChanged){
      onUnzipChanged(true);
    }
  }

  return (
    <div>
      <h2 style={Theme.typography.heading2}>Options</h2>
      <FlexRowCentered style={{marginTop: '2em'}}>
        <Checkbox checked={maintainStructure}
                  onChange={maintainFileStructureChanged}/>
        <label style={{marginLeft: '0.5em'}}>Maintain folder structure at destination</label>
      </FlexRowCentered>
      <p style={Theme.typography.bodyMedium}>File naming</p>
      <Selector items={options}
                selected={options.find(o => o.value === naming)}
                onSelectionChange={namingChanged}/>
      {
        authService.CurrentUser?.roles?.includes(ROLE_ID_COPY_ZIP) &&
        <FlexRowCentered style={{marginTop: '2em'}}>
          <Checkbox checked={unzip}
                    onChange={unzipChanging}/>
          <label style={{marginLeft: '0.5em'}}>Unzip RVT Container Files</label>
          <InfoPopover
            message={"The default setting will copy only the Revit files within each folder. When you enable this option, the destination folder will also include any models linked into the host model."}
            style={{marginLeft: '1em'}}/>
        </FlexRowCentered>
      }
      <FlexRowCentered style={{marginTop: '2em'}}>
        <Checkbox checked={email}
                  onChange={emailChanged}/>
        <label style={{marginLeft: '0.5em'}}>Email me when each run of this task completes</label>
      </FlexRowCentered>
      <FlexRowCentered style={{marginTop: '0.5em', marginLeft: '2em'}}>
        <Checkbox disabled={!emailOnCompletion}
                  checked={csv}
                  onChange={csvChanged}/>
        <label style={{marginLeft: '0.5em'}}>Attach the CSV transfer log</label>
      </FlexRowCentered>
      {
        // TODO: This blocks the UI but only when debugging locally for some reason.  To fix this for local debug use showUnzipWarning && instead of open property.
      }
      <Modal open={showUnzipWarning}>
        <Modal.Header>Unzipping Container Files</Modal.Header>
        <Modal.Body>
          <FlexRow>
            <div>
              <p style={Theme.typography.bodyMedium}>The default setting will copy only the Revit files within each
                folder.</p>
              <p style={Theme.typography.bodyMedium}>When you enable this option, the destination folder will also
                include any models linked into the
                host model.</p>
              <p style={Theme.typography.bodyMedium}><a href={'https://revolutiondesign.biz'} target={"_blank"}
                                                        rel={'noreferrer'}>More
                Information</a></p>
            </div>
            <img alt={'Zip example'} src={zipExample} style={{maxHeight: 270, margin: '1em'}}/>
          </FlexRow>
        </Modal.Body>
        <Modal.Footer>
          <FlexRow style={{justifyContent: 'end'}}>
            <BlueButton style={{marginLeft: '1em'}} onClick={unzipWarningAccepted}>
              <FlexRowCentered>
                <span style={Theme.typography.labelMedium}>OK</span>
              </FlexRowCentered>
            </BlueButton>
          </FlexRow>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SettingsOptions;