// /////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2021 Autodesk, Inc. All rights reserved.
//
//                     ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
// /////////////////////////////////////////////////////////////////////

import {DirectoryUI} from './dataModel/DirectoryUI';
import {ProjectUI} from './dataModel/ProjectUI';
import {
  CustomerUser,
  ExtractLocationType,
  FileDestinationNamingType,
  JobStatusType,
  ProblemDetails,
  ProjectType,
  ProjectWiseCredential
} from './clients/Classes';
import {Task} from './dataModel/Task';
import {BIM360ItemBase} from './dataModel/BIM360ItemBase';
import {FileUI} from './dataModel/FileUI';
import {ConvertRunDate} from "./converters/ConvertRunDate";
import {PageTypes, TutorialType} from "./Enums";
import {
  Constants,
  FILE_TABS,
  PATHS,
  ROLE_ID_ADMIN,
  ROLE_ID_BULKTASKS,
  ROLE_ID_CUSTOMER_READ_ONLY,
  ROLE_ID_PROJECTWISE,
  ROLE_ID_USAGE,
  ROLE_ID_WEBHOOKS,
  SETTINGS_TABS
} from "./Constants";
import {TreeItem} from "./dataModel/TreeItem";
import {ZipEntryUI} from "./dataModel/ZipEntryUI";
import {UsageDataItem} from "./dataModel/UsageDataItem";
import {ZipTaskSetting} from "./dataModel/ZipTaskSetting";
import {FilterItemData} from "./dataModel/FilterItemData";
import {ConvertTaskStatus} from "./converters/ConvertTaskStatus";
import {UsageSummaryUI} from "./dataModel/UsageSummaryUI";
import {ProjectWiseConfigurationUI} from "./dataModel/ProjectWiseConfigurationUI";
import {BulkTaskUI} from "./dataModel/BulkTaskUI";

export function GetDateWithOffset(modifyDays: number): Date {
  const currentTime = new Date().getTime();
  const date = new Date();

  date.setTime(currentTime + (modifyDays * 24 * 60 * 60 * 1000));

  return date;
}

export function DownloadUrl(url: string, fileName: string): void {
  const a: any = document.createElement('a');
  a.href = url;
  a.download = fileName;
  a.target = '_blank';
  document.body.appendChild(a);
  a.style = 'display: none';
  a.click();
  a.remove();
}

export function IsProjectValidForDestination(project: ProjectUI): boolean {
  return project.ProjectType === ProjectType.ACC || project.ProjectType === ProjectType.B360Docs;
}

export function GetFileNameFormattedDate(date: Date): string {
  const year = date.getFullYear().toString();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDay().toString().padStart(2, '0');
  const hour = date.getHours().toString().padStart(2, '0');
  const minute = date.getMinutes().toString().padStart(2, '0');
  const second = date.getSeconds().toString().padStart(2, '0');

  return `${year}-${month}=${day} ${hour}:${minute}:${second}`;
}

export function ObjectToQueryString(object: any): string {
  const str = [];
  for (const p in object) {
    if (object.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(object[p]));
    }
  }

  return str.join('&');
}

export function GetErrorMessage(error: any, operation: string): string {
  let message = null;
  console.error(error);
  if (error instanceof ProblemDetails || error.hasOwnProperty('title')) {
    message = error.title;
  } else {
    if (error.hasOwnProperty('error') && error.error != null) {
      if (error.error instanceof ProblemDetails) {
        message = error.error.title;
      } else {
        // First priority - if error property is a non-blank string use that
        if (typeof error.error === 'string' && error.error !== '') {
          message = error.error;
        } else if (
          error.error.hasOwnProperty('Message')
          && error.error.Message != null
          && typeof (error.error.Message) === 'string'
          && error.error.Message !== ''
        ) {
          // Next - if error is an object with a message property
          message = error.error.Message;
        } else if (
          error.error.hasOwnProperty('message')
          && error.error.message != null
          && typeof (error.error.message) === 'string'
          && error.error.message !== ''
        ) {
          // Next - if error is an object with a message property
          message = error.error.message;
        }
      }
    }

    // If we don't have anything yet check for a message property directly on the error object
    if (
      message == null
      && error.hasOwnProperty('message')
      && error.message != null
      && typeof (error.message) === 'string'
      && error.message !== ''
    ) {
      message = error.message;
    } else if (
      message == null
      && error.hasOwnProperty('Message')
      && error.Message != null
      && typeof (error.Message) === 'string'
      && error.Message !== ''
    ) {
      message = error.Message;
    }

    if (message != null && error.hasOwnProperty('response')) {
      let responseObject: any = null;
      if (typeof error.response === 'string') {
        try {
          responseObject = JSON.parse(error.response);
        } catch (e) {
          message += ` - ${error.response}`;
        }
      } else if (typeof error.response === 'object') {
        responseObject = error.response;
      }

      if (responseObject != null && responseObject.hasOwnProperty('title') && typeof (responseObject.title) === 'string') {
        message += ` - ${responseObject.title}`;
      }
    }

    if (message == null) {
      if (
        error.hasOwnProperty('error')
        && error.error != null
        && typeof error.error === 'string'
        && error.error !== ''
      ) {
        // Last effort - check if it has an error object and use that
        message = error.error;
      } else {
        // Nothing was found, just tell them it's unknown
        message = 'Unknown Error';
      }
    }
  }

  return operation == null || operation === '' ? message : `${operation} Failed: ${message}`;
}

export function GetRecursiveFilePath(folder: DirectoryUI, pathDivider: string): string {
  if (folder.Parent == null) {
    return folder.Name;
  } else {
    const parentVal = GetRecursiveFilePath(folder.Parent, pathDivider);

    if (parentVal === '') {
      return folder.Name;
    } else {
      return `${parentVal}${pathDivider}${folder.Name}`;
    }
  }
}

export function FindProjectItemRecursive(rootFolders: DirectoryUI[], id: string): BIM360ItemBase | null {
  for (const directory of rootFolders) {
    const foundItem = FindBIM360ItemRecursive(directory, id, true, true);
    if (foundItem != null) {
      return foundItem;
    }
  }

  return null;
}

export function ValidateTrigger(task: Task | BulkTaskUI): string[] {
  switch (task.Trigger) {
    case null:
    case undefined:
      return ['No trigger is selected.'];
    case 'OnceNow':
    case 'OnPublish':
      return [];
    case 'OnceLater':
      if (task.StartDate! < new Date()) {
        return ['Start date can not be in the past.'];
      }
      return [];
    case 'Recurring':
      if (task instanceof BulkTaskUI) {
        return [];
      }
      if (task.RecurrenceSettings!.Recurrence !== 'Weekly') {
        return [];
      }

      for (const day of task.RecurrenceSettings!.WeekdaySettings) {
        if (day.Checked) {
          return [];
        }
      }
      return ['You must select at least one day to run checks.'];
  }
}

export function ValidateExports(task: Task): string[] {
  const messages: string[] = [];

  switch (task.ExportLocationType) {
    case ExtractLocationType.None:
    case null:
    case undefined:
      messages.push('You must select an export location type.');
      break;
    case ExtractLocationType.ModelDirectory:
    case ExtractLocationType.ModelSubdirectory:
    case ExtractLocationType.OtherDirectory :
      break;
    case ExtractLocationType.DirectDownload:
      messages.push('Single download is not valid for a task.');
      break;
  }

  switch (task.ExportDestinationNaming) {
    case FileDestinationNamingType.None:
    case null:
    case undefined:
      messages.push('You must select a file naming setting.');
      break;
    case FileDestinationNamingType.Overwrite:
      break;
    case FileDestinationNamingType.AppendTimestamp:
      break;

  }

  return messages;
}

export function uniqueFilter(value: any, index: number, self: any[]) {
  return self.indexOf(value) === index;
}

export function uniqueDateDisplayFilter(value: Date, index: number, self: Date[]) {
  const dateDisplays = self.map(d => ConvertRunDate.Convert(d));
  return dateDisplays.indexOf(ConvertRunDate.Convert(value)) === index;
}

export function TrimString(s: string, c: string): string {
  if (c === ']') {
    c = '\\]';
  }
  if (c === '^') {
    c = '\\^';
  }
  if (c === '\\') {
    c = '\\\\';
  }
  return s.replace(new RegExp(
    '^[' + c + ']+|[' + c + ']+$', 'g'
  ), '');
}

export function GetDefaultTaskFilterOptions(): FilterItemData[] {
  const statusOptions = [
    {value: '', label: Constants.NoFilterString},
    {value: JobStatusType.Scheduled, label: ConvertTaskStatus.Convert(JobStatusType.Scheduled)},
    {value: JobStatusType.Running, label: ConvertTaskStatus.Convert(JobStatusType.Running)},
    {value: JobStatusType.PostProcessing, label: ConvertTaskStatus.Convert(JobStatusType.PostProcessing)},
    {value: JobStatusType.Paused, label: ConvertTaskStatus.Convert(JobStatusType.Paused)},
    {value: JobStatusType.Completed, label: ConvertTaskStatus.Convert(JobStatusType.Completed)},
    {
      value: JobStatusType.PartiallyCompleted,
      label: ConvertTaskStatus.Convert(JobStatusType.PartiallyCompleted)
    },
    {
      value: ConvertTaskStatus.Convert(JobStatusType.Scheduled, true),
      label: ConvertTaskStatus.Convert(JobStatusType.Scheduled, true)
    },
    {value: JobStatusType.Error, label: ConvertTaskStatus.Convert(JobStatusType.Error)},
  ];

  return [
    {
      id: 'status',
      title: 'Status',
      selected: statusOptions[0],
      options: statusOptions,
    }
  ];
}

/**
 *
 * Gets all objects that are in the comparison list but not in the base list.  The property
 * in the comparison key will be used to compare items
 * @param baseItems The base list of items to compare from
 * @param compareItems The list to be used to find unique instances.  All returned items will
 * be from this list.
 * @param comparisonKey The property name to use for comparison to determine if items are unique.
 */
export function GetUnique<T>(baseItems: T[], compareItems: T[], comparisonKey: keyof T): T[] {
  const unique: T[] = [];

  for (const compareItem of compareItems) {
    const baseItem: T | undefined = baseItems.find(d => d[comparisonKey] === compareItem[comparisonKey]);
    if (baseItem == null) {
      unique.push(compareItem);
    }
  }

  return unique;
}

/**
 *
 * Gets all objects that are in the comparison list but not in the base list.  The property
 * in the comparison key will be used to compare items
 * @param baseItems The base list of items to compare from
 * @param compareItems The list to be used to find unique instances.  All returned items will
 * be from this list.
 */
export function GetUniquePrimatives<T>(baseItems: T[], compareItems: T[]): T[] {
  const unique: T[] = [];

  for (const compareItem of compareItems) {
    const baseItem: T | undefined = baseItems.find(d => d === compareItem);
    if (baseItem == null) {
      unique.push(compareItem);
    }
  }

  return unique;
}

export function NameOf<T>(name: keyof T) {
  return name;
}

export function GetTutorialType(currentPath: string): TutorialType | undefined {
  if (currentPath.endsWith(PATHS.TASK)) {
    return 'tasks';
  } else if (currentPath.endsWith(PATHS.REPORT)) {
    return 'reports';
  } else if (currentPath.endsWith(PATHS.DIRECT)) {
    return 'directDownload';
  } else {
    return undefined;
  }
}

export function GetTreeItems(items: BIM360ItemBase[]): TreeItem<BIM360ItemBase>[] {
  const allNodes: TreeItem<BIM360ItemBase>[] = [];

  for (const item of items) {
    const id = GetTreeItemId(item);
    const node = new TreeItem<BIM360ItemBase>(id, item);
    if ((item instanceof DirectoryUI || item instanceof FileUI) && item.SubItems.length > 0) {
      node.children = GetTreeItems(item.SubItems);
    }
    allNodes.push(node);
  }

  return allNodes;
}

export function GetTreeItemId(item: BIM360ItemBase): string {
  return item instanceof ZipEntryUI ? `${item.Id}_${item.Name}` : item.Id;
}

export function GetUsageTreeItems(items: UsageDataItem[]): TreeItem<UsageDataItem>[] {
  const allNodes: TreeItem<UsageDataItem>[] = [];

  for (const item of items) {
    const node = new TreeItem<UsageDataItem>(item.ID!, item);
    if (item.Children.length > 0) {
      node.children = GetUsageTreeItems(item.Children);
    }
    allNodes.push(node);
  }

  return allNodes;
}

export function AreAnyBranchesUnloaded(item: DirectoryUI): boolean {
  if (!item.AreItemsPopulated) {
    return true;
  }

  for (const folder of item.SubFolders) {
    if (AreAnyBranchesUnloaded(folder)) {
      return true;
    }
  }

  for (const file of item.Files) {
    if (file.IsComposite && !file.AreItemsPopulated) {
      return true;
    }
  }

  return false;
}

export function StringToList(value: string): string[] {
  const rawSplit = value.split(',');

  const list: string[] = [];
  rawSplit.forEach(e => {
    if (e == null || e.trim() === '') {
      return;
    }
    list.push(e.trim());
  });

  return list;
}

export function ListToString(values: string[]): string {
  return values.join(', ');
}

export function GetPropertyDisplayString(item: any, key: string): string | undefined {
  if (item == null) {
    return undefined;
  }
  const value: any = item[key];

  if (value == null) {
    return undefined;
  }

  if (value instanceof Object) {
    return JSON.stringify(value);
  }

  return value.toString();
}

export function IncludeZip(entry: ZipTaskSetting, task: Task): boolean {
  const parent = task.Files.find(f => f instanceof FileUI && f.Id === entry.File.Id);
  return parent == null;
}

export function IsPageAvailableToUser(
  page: { path: string, uiVisible: boolean, type: PageTypes },
  user: CustomerUser | undefined
): boolean {
  switch (page.type) {
    case PageTypes.auth:
      return true;
    case PageTypes.protected:
      return user != null;
    case PageTypes.customers:
      return user?.roles != null
        && (user.roles?.includes(ROLE_ID_ADMIN)
          || user.roles?.includes(ROLE_ID_CUSTOMER_READ_ONLY));
    case PageTypes.usage:
      return user?.roles != null
        && (user.roles?.includes(ROLE_ID_ADMIN)
          || user.roles?.includes(ROLE_ID_USAGE));
    case PageTypes.settings:
      return user?.roles != null
        && (user.roles?.includes(ROLE_ID_ADMIN)
          || user.roles?.includes(ROLE_ID_WEBHOOKS)
          || user.roles?.includes(ROLE_ID_PROJECTWISE));
    default:
      return false;
  }
}

export function IsTabAvailableToUser(tab: string, user: CustomerUser | undefined): boolean {
  switch (tab) {
    case SETTINGS_TABS.PROJECTWISE:
    case FILE_TABS.PROJECTWISE:
      return user?.roles != null
        && (user.roles?.includes(ROLE_ID_ADMIN)
          || user.roles?.includes(ROLE_ID_PROJECTWISE));
    case SETTINGS_TABS.WEBHOOKS:
      return user?.roles != null
        && (user.roles?.includes(ROLE_ID_ADMIN)
          || user.roles?.includes(ROLE_ID_WEBHOOKS));
    default:
      return true;
  }
}

export function IsBulkTasksAvailableToUser(user: CustomerUser | undefined): boolean {
  return user?.roles != null && (user.roles.includes(ROLE_ID_ADMIN) || user.roles.includes(ROLE_ID_BULKTASKS));
}

function FindBIM360ItemRecursive(
  parentDirectory: DirectoryUI,
  id: string, includeFiles: boolean,
  includeDirectories: boolean
): BIM360ItemBase | null {
  if (parentDirectory.Id === id && includeDirectories) {
    return parentDirectory;
  }

  if (includeFiles && parentDirectory.Files != null) {
    const file = parentDirectory.Files.find(f => f.Id === id);
    if (file != null) {
      return file;
    }

    const zip = parentDirectory.Files
      .flatMap(f => f.SubItems)
      .find(z => GetTreeItemId(z) === id || z.Id === id);

    if (zip != null) {
      return zip;
    }
  }

  if (includeDirectories && parentDirectory.SubFolders != null) {
    for (const child of parentDirectory.SubFolders) {
      const foundDirectory = FindBIM360ItemRecursive(child, id, includeFiles, includeDirectories);
      if (foundDirectory != null) {
        return foundDirectory;
      }
    }
  }

  return null;
}

export function StartOfDay(date: Date, makeUtc: boolean = false): Date {
  const baseDateNumber = date.setHours(0, 0, 0, 0);
  return makeUtc ? new Date(baseDateNumber - (date.getTimezoneOffset() * 60000)) : new Date(baseDateNumber);
}

export function EndOfDay(date: Date, makeUtc: boolean = false): Date {
  const baseDateNumber = date.setHours(23, 59, 59, 0);
  return makeUtc ? new Date(baseDateNumber - (date.getTimezoneOffset() * 60000)) : new Date(baseDateNumber);
}

export function GenerateRandomString(length: number, possibleCharacters?: string | undefined): string {
  // noinspection SpellCheckingInspection
  const chars = possibleCharacters ?? 'abcdefghijkmnopqrstuvwxyzABCDEFGHIJKLMNPQRSTUVWXYZ23456789';

  let retVal = '';
  for (let i = 0, n = chars.length; i < length; i++) {
    retVal += chars.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
}

export function CombineUsageSummaries(summaries: UsageSummaryUI[]): UsageSummaryUI {
  const totalRuns = summaries.reduce((total, summary) => total + summary.TotalRunCount, 0);
  const totalRunSeconds = summaries.reduce((total, summary) => total + summary.TotalRunTimeSeconds, 0);
  const final = new UsageSummaryUI(totalRuns, totalRunSeconds);

  for (const summary of summaries) {
    summary.CustomerData.forEach(c => {
      let existing = final.CustomerData.findIndex(e => e.ID === c.ID);
      if (existing < 0) {
        final.CustomerData.push(c);
      } else {
        final.CustomerData[existing] = CombineDataItems(final.CustomerData[existing], c);
      }
    });
  }

  return final;
}

export function SetConfigurationCredentials(configs: ProjectWiseConfigurationUI[], credentials: ProjectWiseCredential[]): boolean {
  let hasUpdate = false;

  configs.forEach(config => {
    const credential = credentials.find(c => c.id === config.ApiConfiguration.credentialId);
    if (credential != null && config.CredentialKey !== credential.key) {
      config.CredentialKey = credential.key;
      hasUpdate = true;
    }
  });

  return hasUpdate;
}

export function GetUniqueProjectStructureData(allItems: (DirectoryUI | FileUI | ZipTaskSetting)[]): {
  projectId: string,
  directoryIds: string[]
}[] {
  const projectData: { projectId: string, directoryIds: string[] }[] = [];

  allItems.forEach(i => {
    const projectId = i instanceof DirectoryUI || i instanceof FileUI
      ? i.ProjectId
      : i.File.ProjectId;
    const directoryId = i instanceof DirectoryUI
      ? i.Id
      : i instanceof FileUI
        ? i.DirectoryId
        : i.File.DirectoryId;
    const existing = projectData.find(e => e.projectId === projectId);
    if (existing == null) {
      projectData.push({projectId: projectId, directoryIds: [directoryId]});
    } else {
      if (!existing.directoryIds.includes(directoryId)) {
        existing.directoryIds.push(directoryId);
      }
    }
  });

  return projectData;
}

function CombineDataItems(target: UsageDataItem, source: UsageDataItem): UsageDataItem {
  target.JobRuns! += source.JobRuns!;
  target.RunTimeSeconds! += source.RunTimeSeconds!;

  source.Children.forEach(c => {
    const existing = target.Children.findIndex(e => e.ID === c.ID);
    if (existing < 0) {
      target.Children.push(c);
    } else {
      target.Children[existing] = CombineDataItems(target.Children[existing], c);
    }
  });

  return {...target};
}

export function DeepEquals(x: object | null | undefined, y: object | null | undefined): boolean {
  if (x === y)
    return true;
  if (!x || !y || x.constructor !== y.constructor)
    return false;
  const kx = Object.keys(x);
  const ky = Object.keys(y);

  if (kx.length != ky.length) {
    return false;
  }

  let keysMatch = true;
  kx.forEach(k => {
    if (!keysMatch) {
      return;
    }

    if (!ky.includes(k)) {
      keysMatch = false;
      return;
    }

    const yValue = y[k as keyof object];
    const xValue = x[k as keyof object];

    if (typeof yValue !== typeof xValue) {
      keysMatch = false;
      return;
    }

    if (typeof yValue === 'object') {
      const xObject = xValue as object;
      const yObject = yValue as object;
      if (yObject instanceof Date) {
        keysMatch = (xObject as Date).getTime() == (yObject as Date).getTime();
      } else if (yObject instanceof RegExp) {
        keysMatch = xObject.toString() === yObject.toString();
      } else {
        keysMatch = DeepEquals(xValue, yValue);
      }
    } else if (Array.isArray(xValue)) {
      const xArray = xValue as Array<any>;
      const yArray = yValue as Array<any>;

      if (xArray.length !== yArray.length) {
        keysMatch = false;
        return;
      }

      for (let i = 0; i < xArray.length; i++) {
        const xVal = xArray[i];
        const yVal = yArray[i];

        if (typeof yVal !== typeof xVal) {
          keysMatch = false;
          return;
        }

        const match = typeof xVal === 'object' ? DeepEquals(xVal, yVal) : xVal === yVal;
        if (!match) {
          keysMatch = false;
          return;
        }
      }
    } else {
      keysMatch = xValue === yValue;
    }
  });

  return keysMatch;
}
