////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import {UsageActions} from "../../Enums";
import {UsageState} from "../states/UsageState";

export function reducer(state: UsageState, action: { type: UsageActions, payload: any }): UsageState {
  switch (action.type) {
    case UsageActions.loadingMoreData:
      return {...state, loadingMoreData: action.payload};
    case UsageActions.hasMoreData:
      return {...state, hasMoreData: action.payload};
    case UsageActions.result:
      return {...state, result: action.payload};
    case UsageActions.loading:
      return {...state, loading: action.payload};
    case UsageActions.multipleActions:
      return {...state, ...action.payload};
    case UsageActions.dataItems:
      return {...state, dataItems: action.payload};
    case UsageActions.customerIds:
      return {...state, customerIds: action.payload};
    case UsageActions.userIds:
      return {...state, userIds: action.payload};
    case UsageActions.jobIds:
      return {...state, jobIds: action.payload};
    case UsageActions.statuses:
      return {...state, statuses: action.payload};
    case UsageActions.startDate:
      return {...state, startDate: action.payload};
    case UsageActions.useStartDate:
      return {...state, useStartDate: action.payload};
    case UsageActions.endDate:
      return {...state, endDate: action.payload};
    case UsageActions.useEndDate:
      return {...state, useEndDate: action.payload};
  }
}
