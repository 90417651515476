////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import React, {useState} from 'react';
import {DatePicker} from "@adsk/alloy-react-date-picker";
import Selector from "./Selector";
import {DefaultItem} from "@adsk/alloy-react-dropdown";
import {TaskTrigger} from "../Enums";
import {FlexColumn, FlexRow} from '../CommonStyledComponents';
import {ConvertAutodeskSchedule} from "../converters/ConvertAutodeskSchedule";
import Theme from "@adsk/alloy-react-theme";
import Schedule from '@adsk/alloy-react-schedule';
import {RecurrenceSettings} from "../dataModel/RecurrenceSettings";
import TimePicker from "@adsk/alloy-react-time-picker";
import {DeepEquals} from "../Utility";

const SettingsTrigger = (
  {
    recurrenceSettings,
    taskTrigger,
    startTime,
    allowRecurring,
    onTriggerChanged,
    onScheduleChanged,
    onStartTimeChanged
  }: {
    recurrenceSettings: RecurrenceSettings | undefined,
    taskTrigger: TaskTrigger | undefined,
    startTime: Date | undefined,
    allowRecurring: boolean,
    onTriggerChanged?: (newTrigger: TaskTrigger | undefined, newStartDate?: Date | undefined, newRecurrence?: RecurrenceSettings | undefined) => void,
    onScheduleChanged?: (newSchedule: RecurrenceSettings | undefined) => void,
    onStartTimeChanged?: (newStart: Date | undefined) => void
  }) => {
  const convertedSchedule = recurrenceSettings == null
    ? undefined
    : ConvertAutodeskSchedule.GetAutodeskSchedule(recurrenceSettings);
  const [trigger, setTrigger] = useState(taskTrigger);
  const [schedule] = useState(convertedSchedule);
  const [startDate, setStartDate] = useState(startTime);
  const [showDays, setShowDays] = useState(recurrenceSettings?.Recurrence !== 'Weekly');

  const options: DefaultItem[] = allowRecurring
    ? [
      {value: 'OnceNow', label: 'Once Now'},
      {value: 'OnceLater', label: 'Once Later'},
      {value: 'Recurring', label: 'Recurrence'},
    ]
    : [
      {value: 'OnceNow', label: 'Once Now'},
      {value: 'OnceLater', label: 'Once Later'},
    ];

  function triggerChange(value: DefaultItem | null | undefined): void {
    if (value == null) {
      return;
    }
    const newTrigger = value.value as TaskTrigger;
    setTrigger(newTrigger);

    const date = new Date();
    date.setHours(date.getHours() + 1, 0, 0, 0);

    let newDate: Date | undefined;
    let newRecurrence: RecurrenceSettings | undefined;

    switch (newTrigger) {
      case "OnceLater":
        setStartDate(date);
        newDate = date;
        break;
      case "Recurring":
        setStartDate(date);
        newDate = date;
        const defaultSchedule = new RecurrenceSettings(date, -1);
        newRecurrence = defaultSchedule;
        break;
    }

    if (onTriggerChanged) {
      onTriggerChanged(newTrigger, newDate, newRecurrence);
    }
  }

  function scheduleChange(e: any): void {
    const newSettings = ConvertAutodeskSchedule.GetUiSchedule(e);
    if (DeepEquals(newSettings, recurrenceSettings)) {
      return;
    }
    setShowDays(newSettings?.Recurrence !== 'Weekly')
    if (onScheduleChanged) {
      onScheduleChanged(newSettings);
    }
  }

  function timeChange(hours: number, minutes: number): void {
    if (startDate == null) {
      return;
    }
    const date = new Date(startDate.setHours(hours, minutes, 0, 0));
    setStartDate(date);
    if (onStartTimeChanged) {
      onStartTimeChanged(date);
    }
  }

  function dateChange(newDate: Date | null | undefined): void {
    if (startDate == null || newDate == null) {
      return;
    }

    // Set the values here instead of just taking the full date to avoid blanking the time setting.
    const date = new Date(startDate.setFullYear(newDate.getFullYear(), newDate.getMonth(), newDate.getDate()));
    // Need to have this as a separate state variable, or it doesn't want to update properly.
    setStartDate(date);
    if (onStartTimeChanged) {
      onStartTimeChanged(date);
    }
  }

  return (
    <div>
      <h2 style={Theme.typography.heading2}>Trigger</h2>
      <FlexRow>
        <div style={{flex: 1}}>
          <Selector
            items={options}
            selected={options.find(o => o.value === trigger) ?? options[0]}
            onSelectionChange={triggerChange}/>
        </div>
        <div style={{flex: 3, marginLeft: '2em'}}>
          {
            trigger === 'OnceLater' &&
            <FlexRow>
              <FlexColumn>
                <span style={Theme.typography.labelSmall}>Run on</span>
                <DatePicker
                  value={startDate}
                  disablePastDates={true}
                  onChange={e => dateChange(e.date)}/>
              </FlexColumn>
              <FlexColumn>
                <span style={Theme.typography.labelSmall}>Run at</span>
                <TimePicker value={{hours: startDate?.getHours() ?? 0, minutes: startDate?.getMinutes() ?? 0}}
                            onChange={t => timeChange(t?.hours ?? 0, t?.minutes ?? 0)}/>
              </FlexColumn>
            </FlexRow>
          }
          {
            trigger === 'Recurring' &&
            <Schedule onChange={scheduleChange} data={schedule} hideStepper={!showDays}/>
          }
        </div>
      </FlexRow>
    </div>
  );
};

export default SettingsTrigger;