////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import React, {CSSProperties, ReactElement, useState} from 'react';
import {ColumnDef, getCoreRowModel, getSortedRowModel, SortingState, useReactTable} from "@tanstack/react-table";
import {TableCell, TableHeader, TableHeaderGroup, TableRow, useTable} from "@adsk/alloy-react-table";

const Table = <T, >({columns, data, renderLastRow, style, initialSortingColumn, initialSortDescending = false}: {
  columns: ColumnDef<T>[],
  data: T[],
  renderLastRow?: () => ReactElement | false | undefined,
  style?: CSSProperties,
  initialSortingColumn?: string,
  initialSortDescending?: boolean
}) => {
  const initialSortingState = initialSortingColumn == null ? [] : [{id: initialSortingColumn, desc: initialSortDescending}];
  const [sorting, setSorting] = useState<SortingState>(initialSortingState)

  const table = useReactTable({
    columns: columns,
    data: data,
    state: {sorting: sorting},
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    enableSorting: true,
    getSortedRowModel: getSortedRowModel()
  });

  const {
    getTableRowProps,
    getTableCellProps,
    getTableHeaderProps,
    getTableHeaderGroupProps
  } = useTable({table});

  return (
    <table style={style}>
      <thead>
      {table.getHeaderGroups().map(headerGroup => (
        <TableHeaderGroup key={headerGroup.id} {...getTableHeaderGroupProps(headerGroup)}>
          {headerGroup.headers.map(header => (
            <TableHeader key={header.id} {...getTableHeaderProps(header)} />
          ))}
        </TableHeaderGroup>
      ))}
      </thead>
      <tbody>
      {table.getRowModel().rows.map(row => (
        <TableRow key={row.id} {...getTableRowProps(row)} canSelect={false}>
          {row.getVisibleCells().map(cell => (
            <TableCell key={cell.id} {...getTableCellProps(cell)} />
          ))}
        </TableRow>
      ))}
      {renderLastRow && renderLastRow()}
      </tbody>
    </table>
  );
};

export default Table;