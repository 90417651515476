////////////////////////////////////////////////////////////////////////////////
//                                                                             /
//                                                                             /
//  (C) Copyright 2024 Autodesk, Inc. All rights reserved.                     /
//                                                                             /
//                       ****  CONFIDENTIAL MATERIAL  ****                     /
//                                                                             /
//  The information contained herein is confidential, proprietary to           /
//  Autodesk, Inc., and considered a trade secret.  Use of this information    /
//  by anyone other than authorized employees of Autodesk, Inc. is granted     /
//  only under a written nondisclosure agreement, expressly prescribing        /
//  the scope and manner of such use.                                          /
//                                                                             /
////////////////////////////////////////////////////////////////////////////////

import {ProjectUI} from "../../dataModel/ProjectUI";
import {BulkTaskUI} from "../../dataModel/BulkTaskUI";
import {EDIT_TAB_BULK_IDS, EDIT_TABS_BULK} from "../../Constants";
import {ReactNode} from "react";

export class BulkTaskEditState {
  Projects: ProjectUI[];

  Task: BulkTaskUI | undefined;
  OriginalTask: BulkTaskUI | undefined;

  Loading = false;
  LoadingProjects = false;
  Saving = false;

  IsNewTask = false;
  IsDuplicating = false;

  showCancelConfirm = false;
  SelectedTab: string;
  SelectedProjectDestination: ProjectUI | undefined;
  ExpandedDestination: string[];

  DisableSave = false;
  SaveTooltip: ReactNode|undefined;

  constructor() {
    this.SelectedTab = EDIT_TAB_BULK_IDS[EDIT_TABS_BULK.NAME];
    this.Projects = [];
    this.ExpandedDestination = [];
  }
}