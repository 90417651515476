////////////////////////////////////////////////////////////////////////////////
//                                                                             /
//                                                                             /
//  (C) Copyright 2024 Autodesk, Inc. All rights reserved.                     /
//                                                                             /
//                       ****  CONFIDENTIAL MATERIAL  ****                     /
//                                                                             /
//  The information contained herein is confidential, proprietary to           /
//  Autodesk, Inc., and considered a trade secret.  Use of this information    /
//  by anyone other than authorized employees of Autodesk, Inc. is granted     /
//  only under a written nondisclosure agreement, expressly prescribing        /
//  the scope and manner of such use.                                          /
//                                                                             /
////////////////////////////////////////////////////////////////////////////////

import {BulkTaskEditState} from "../states/BulkTaskEditState";
import {BulkTaskEditActions} from "../../Enums";

export function reducer(state: BulkTaskEditState, action: {
  type: BulkTaskEditActions,
  payload: any
}): BulkTaskEditState {
  switch (action.type) {
    case BulkTaskEditActions.multipleActions:
      return {...state, ...action.payload};
    case BulkTaskEditActions.projects:
      return {...state, Projects: action.payload};
    case BulkTaskEditActions.task:
      return {...state, Task: action.payload};
    case BulkTaskEditActions.originalTask:
      return {...state, OriginalTask: action.payload};
    case BulkTaskEditActions.loading:
      return {...state, Loading: action.payload};
    case BulkTaskEditActions.loadingProjects:
      return {...state, LoadingProjects: action.payload};
    case BulkTaskEditActions.saving:
      return {...state, Saving: action.payload};
    case BulkTaskEditActions.isNewTask:
      return {...state, IsNewTask: action.payload};
    case BulkTaskEditActions.isDuplicating:
      return {...state, IsDuplicating: action.payload};
    case BulkTaskEditActions.showCancelConfirm:
      return {...state, showCancelConfirm: action.payload};
    case BulkTaskEditActions.selectedTab:
      return {...state, SelectedTab: action.payload};
    case BulkTaskEditActions.selectedProjectDestination:
      return {...state, SelectedProjectDestination: action.payload};
    case BulkTaskEditActions.expandedDestination:
      return {...state, ExpandedDestination: action.payload};
    case BulkTaskEditActions.disableSave:
      return {...state, DisableSave: action.payload};
    case BulkTaskEditActions.saveTooltip:
      return {...state, SaveTooltip: action.payload};
  }
}