// /////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2021 Autodesk, Inc. All rights reserved.
//
//                     ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
// /////////////////////////////////////////////////////////////////////

export class Constants {
  static get NoFilterString(): string {
    return '-Any-';
  }

  static get MaxJobUrlCharacters(): number {
    return 150;
  }

  static get SecretKeyLength(): number {
    return 16;
  }
}

export const PAGES = {
  ROOT: "ROOT",
  DOOR: "DOOR",
  LANDING: "LANDING",
  TASK: "TASK",
  REPORT: "REPORT",
  SETTINGS: 'SETTINGS',
  CUSTOMERS: "CUSTOMERS",
  USAGE: "USAGE",
  JOBS: "JOBS",
  DIRECT: "DIRECT",
};

export const PATHS = {
  [PAGES.ROOT]: "/app",
  [PAGES.DOOR]: "/door",
  [PAGES.LANDING]: "/landing",
  [PAGES.TASK]: "tasks",
  [PAGES.REPORT]: "report",
  [PAGES.SETTINGS]: 'settings',
  [PAGES.CUSTOMERS]: "customers",
  [PAGES.USAGE]: "usage",
  [PAGES.JOBS]: "jobs",
  [PAGES.DIRECT]: "direct",
};

export const PAGE_TITLES = {
  [PAGES.ROOT]: "Root",
  [PAGES.DOOR]: "Door",
  [PAGES.LANDING]: "Landing",
  [PAGES.TASK]: "Tasks",
  [PAGES.REPORT]: "Reports",
  [PAGES.SETTINGS]: 'Settings',
  [PAGES.CUSTOMERS]: "Customers",
  [PAGES.USAGE]: "Usage",
  [PAGES.JOBS]: "Jobs",
  [PAGES.DIRECT]: "Single Download",
};

export const EDIT_TABS = {
  NAME: "NAME",
  SOURCE: "SOURCE",
  DESTINATION: "DESTINATION",
  TRIGGER: "TRIGGER",
  OPTIONS: "OPTIONS",
  SUMMARY: "SUMMARY",
}

export const EDIT_TAB_TITLES = {
  [EDIT_TABS.NAME]: "Name",
  [EDIT_TABS.SOURCE]: "Source",
  [EDIT_TABS.DESTINATION]: "Destination",
  [EDIT_TABS.TRIGGER]: "Trigger",
  [EDIT_TABS.OPTIONS]: "Options",
  [EDIT_TABS.SUMMARY]: "Summary",
}

export const EDIT_TAB_IDS = {
  [EDIT_TABS.NAME]: "tbName",
  [EDIT_TABS.SOURCE]: "tbSource",
  [EDIT_TABS.DESTINATION]: "tbDestination",
  [EDIT_TABS.TRIGGER]: "tbTrigger",
  [EDIT_TABS.OPTIONS]: "tbOptions",
  [EDIT_TABS.SUMMARY]: "tbSummary",
}

export const EDIT_TABS_BULK = {
  NAME: "NAME",
  SOURCE: "SOURCE",
  DESTINATION: "DESTINATION",
  TRIGGER: "TRIGGER",
  SUMMARY: "SUMMARY",
}

export const EDIT_TAB_BULK_TITLES = {
  [EDIT_TABS_BULK.NAME]: "Name",
  [EDIT_TABS_BULK.SOURCE]: "Source",
  [EDIT_TABS_BULK.DESTINATION]: "Destination",
  [EDIT_TABS_BULK.TRIGGER]: "Trigger",
  [EDIT_TABS_BULK.SUMMARY]: "Summary",
}

export const EDIT_TAB_BULK_IDS = {
  [EDIT_TABS_BULK.NAME]: "tbName",
  [EDIT_TABS_BULK.SOURCE]: "tbSource",
  [EDIT_TABS_BULK.DESTINATION]: "tbDestination",
  [EDIT_TABS_BULK.TRIGGER]: "tbTrigger",
  [EDIT_TABS_BULK.SUMMARY]: "tbSummary",
}

export const SETTINGS_TABS = {
  WEBHOOKS: "WEBHOOKS",
  PROJECTWISE: "PROJECTWISE",
}

export const SETTINGS_TAB_TITLES = {
  [SETTINGS_TABS.WEBHOOKS]: "Webhooks",
  [SETTINGS_TABS.PROJECTWISE]: "ProjectWise",
}

export const SETTINGS_TAB_IDS = {
  [SETTINGS_TABS.WEBHOOKS]: "tbWebhooks",
  [SETTINGS_TABS.PROJECTWISE]: "tbProjectWise",
}

export const REPOSITORY_TABS = {
  MANUAL: "MANUAL",
  SEARCH: "SEARCH",
}

export const REPOSITORY_TAB_TITLES = {
  [REPOSITORY_TABS.MANUAL]: "Manual Entry",
  [REPOSITORY_TABS.SEARCH]: "Search",
}

export const REPOSITORY_TAB_IDS = {
  [REPOSITORY_TABS.MANUAL]: "tbManual",
  [REPOSITORY_TABS.SEARCH]: "tbSearch",
}

export const FILE_TABS = {
  FORGE: "FORGE",
  PROJECTWISE: "PROJECTWISE",
}

export const FILE_TAB_TITLES = {
  [FILE_TABS.FORGE]: "Docs",
  [FILE_TABS.PROJECTWISE]: "ProjectWise",
}

export const FILE_TAB_IDS = {
  [FILE_TABS.FORGE]: "tbDocs",
  [FILE_TABS.PROJECTWISE]: "tbProjectWise",
}

export const ROLE_ID_ADMIN = 'bit_admin';
export const ROLE_ID_USAGE = 'bit_usage';
export const ROLE_ID_CUSTOMER_READ_ONLY = 'bit_customer_read_only';
export const ROLE_ID_WEBHOOKS = 'bit_webhooks';
export const ROLE_ID_BULKTASKS = 'bit_bulktasks';
export const ROLE_ID_PROJECTWISE = 'bit_pw';
export const ROLE_ID_COPY_ZIP = 'deckard_copy_composite';
