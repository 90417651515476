////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import {ProjectUI} from "../dataModel/ProjectUI";
import Default_Project from '../assets/Default_Project.png';
import {ProjectType} from "../clients/Classes";
import {EntitlementsProject} from "@adsk/alloy-provider";

export class ConvertAutodeskProject {
  public static GetAutodeskProject(project: ProjectUI): EntitlementsProject {
    let platform: string = 'bim360';
    let platformDisplay: string = 'Unknown';
    switch (project.ProjectType) {
      case ProjectType.Unknown:
        // Nothing to do here.
        break;
      case ProjectType.ACC:
        platform = 'acc';
        platformDisplay = 'Autodesk Docs';
        break;
      case ProjectType.A360:
        platformDisplay = 'A360 Team';
        break;
      case ProjectType.B360Team:
        platformDisplay = 'BIM 360 Team';
        break;
      case ProjectType.B360Docs:
        platformDisplay = 'BIM 360 Docs';
        break;
    }
    return {
      accountId: "",
      products: [],
      region: "",
      id: project.Id,
      name: project.Name,
      accountName: `${project.HubName} (${platformDisplay})`,
      imageUrl: project.ImagePath ?? Default_Project,
      defaultUrl: '',
      platform: platform,
      classification: 'production'
    };
  }
}