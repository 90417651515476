////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import React, {useEffect, useMemo, useReducer} from 'react';
import {UsageDataService} from "../services/UsageDataService";
import {reducer} from "../components/reducers/JobsReducer";
import {JobsState} from "../components/states/JobsState";
import {
  BlueButton, CenteringContainer,
  ColumnLeft,
  ColumnRight,
  ContentWrapper,
  FlexColumn,
  FlexFill,
  FlexRow,
  FlexRowCentered
} from '../CommonStyledComponents';
import Selector from "../components/Selector";
import {DeckardJobDataEntry, JobRunItemStatusType, UsageSortType} from "../clients/Classes";
import {ConvertRunDate} from "../converters/ConvertRunDate";
import {JobsActions} from "../Enums";
import IdInput from "../components/IdInput";
import {
  DownloadUrl,
  EndOfDay,
  GetErrorMessage,
  GetFileNameFormattedDate,
  StartOfDay
} from "../Utility";
import {PageSizeService} from "../services/PageSizeService";
import Theme from "@adsk/alloy-react-theme";
import {ArrowRotateTwoIcon, CloudDownArrowIcon} from "@adsk/alloy-react-icon";
import ProgressRing from "@adsk/alloy-react-progress-ring";
import Modal from "@adsk/alloy-react-modal";
import Illustration from "@adsk/alloy-react-illustration";
import {DatePicker} from "@adsk/alloy-react-date-picker";
import Checkbox, {CheckboxState} from "@adsk/alloy-react-checkbox";
import Table from "../components/Table";
import {LoadMoreDataRow} from "@adsk/alloy-react-table";
import ObjectPropertyDetail from "../components/ObjectPropertyDetail";
import {
  DropdownMenu,
  MultiDropdownTokenControl,
  useMultiDropdown
} from "@adsk/alloy-react-dropdown";
import {DetailedUsageRequest, UsageFilterDto, UsageSortDto} from "../clients/V2Classes";

const service = new UsageDataService();
const pageSize = PageSizeService.GetPageSize('jobs');
let paginationToken: string | undefined = undefined;

const Jobs = () => {
  const defaultState = new JobsState();

  const queryParams = new URLSearchParams(window.location.search);
  const customersRaw = queryParams.get('customers');
  const usersRaw = queryParams.get('users');
  const jobsRaw = queryParams.get('jobs');

  const customers = customersRaw == null ? [] : customersRaw.split(',');
  const users = usersRaw == null ? [] : usersRaw.split(',');
  const jobs = jobsRaw == null ? [] : jobsRaw.split(',');

  if (customers.length > 0 || users.length > 0 || jobs.length > 0) {
    defaultState.customerIds = customers;
    defaultState.userIds = users;
    defaultState.jobIds = jobs;
  }

  const [state, dispatch] = useReducer(reducer, defaultState);

  useEffect(() => {
    if (state.customerIds.length === 0 && state.userIds.length === 0 && state.jobIds.length === 0 && state.statuses.length === 0) {
      return;
    }

    getData();
  }, []);

  function downloadRaw(): void {
    dispatch({type: JobsActions.loadingCsv, payload: true});
    const startTime = new Date();

    const customerIds = state.customerIds.length > 0 ? state.customerIds : undefined;
    const userIds = state.userIds.length > 0 ? state.userIds : undefined;
    const jobIds = state.jobIds.length > 0 ? state.jobIds : undefined;
    const startDate = state.useStartDate && state.startDate != null ? StartOfDay(state.startDate, true) : undefined;
    const endDate = state.useEndDate && state.endDate != null ? EndOfDay(state.endDate, true) : undefined;
    const sortType = state.sortingField;
    const sortAscending = state.sortAscending;
    const statuses = state.statuses.length > 0 ? state.statuses : undefined;

    service.StartUsageExport(new DetailedUsageRequest({
      filter: new UsageFilterDto({
        customerIds: customerIds,
        userIds: userIds,
        jobIds: jobIds,
        startTime: startDate,
        endTime: endDate
      }),
      statuses: statuses,
      sort: new UsageSortDto({
        sortType: sortType,
        ascending: sortAscending
      })
    }))
      .then(dto => {
          console.log(`Started csv export with id ${dto.id}`);
          const timerId = setInterval(() => {
            service.GetUsageDownload(dto.id!)
              .then(download => {
                if (download.isError) {
                  clearInterval(timerId);
                  dispatch({type: JobsActions.loadingCsv, payload: false});
                  alert(GetErrorMessage(new Error(download.errorMessage), 'Download CSV data'));
                }

                if (!download.isReady) {
                  return;
                }

                dispatch({type: JobsActions.loadingCsv, payload: false});

                clearInterval(timerId);

                const endTime = new Date();
                const elapsedSeconds = Math.abs(endTime.getTime() - startTime.getTime()) / 1000;
                console.log(`Completed csv export in ${elapsedSeconds} seconds`);

                const currentDate = GetFileNameFormattedDate(new Date());
                const fileName = `Replication Tool CSV Data ${currentDate}.csv`;

                DownloadUrl(download.downloadUrl!, fileName);
              })
              .catch(error => {
                clearInterval(timerId);
                dispatch({type: JobsActions.loadingCsv, payload: false});
                onError(error, 'Download CSV data');
              });
          }, 3000);
        }
      ).catch(er => {
      dispatch({type: JobsActions.loadingCsv, payload: false});
      onError(er, 'Download CSV data');
    });
  }

  function getData(): void {
    const messages: string[] = [];

    if (state.useStartDate && state.startDate == null) {
      messages.push('Start date is not set');
    }

    if (state.useEndDate && state.endDate == null) {
      messages.push('End date is not set');
    }

    if (messages.length > 0) {
      alert(`You must fix the following before you can filter:\n\n${messages.join('\n')}`);
      return;
    }

    const customerIds = state.customerIds.length > 0 ? state.customerIds : undefined;
    const jobIds = state.jobIds.length > 0 ? state.jobIds : undefined;
    const userIds = state.userIds.length > 0 ? state.userIds : undefined;
    const start = state.useStartDate && state.startDate != null ? StartOfDay(state.startDate) : undefined;
    const end = state.useEndDate && state.endDate != null ? EndOfDay(state.endDate!) : undefined;
    const statuses = state.statuses.length > 0 ? state.statuses : undefined;

    dispatch({
      type: JobsActions.multipleActions,
      payload: {loading: true, hasMoreData: false, loadingMoreData: false}
    });

    service.GetUsageDetail(
      statuses,
      customerIds,
      userIds,
      jobIds,
      start ?? undefined,
      end ?? undefined,
      state.sortingField,
      state.sortAscending,
      undefined,
      pageSize)
      .then(
        data => {
          paginationToken = data.isDone ? undefined : data.paginationData!.paginationToken;
          dispatch({
            type: JobsActions.multipleActions, payload: {
              loading: false,
              dataItems: data.entries ?? [],
              loadingMoreData: false,
              hasMoreData: !data.isDone,
            }
          });

          if (!data.isDone && (pageSize == null || data.entries!.length < pageSize)) {
            state.dataItems = data.entries!;
            GetNextSet();
          }
        },
        er => {
          alert(GetErrorMessage(er, 'Filter Data'));
          dispatch({type: JobsActions.loading, payload: false});
        }
      )
      .catch(error => onError(error, 'Get Usage Detail'));
  }

  function GetNextSet(): void {
    if (paginationToken == null || paginationToken === '') {
      return;
    }

    const customerIds = state.customerIds.length > 0 ? state.customerIds : undefined;
    const jobIds = state.jobIds.length > 0 ? state.jobIds : undefined;
    const userIds = state.userIds.length > 0 ? state.userIds : undefined;
    const start = state.useStartDate && state.startDate != null ? StartOfDay(state.startDate) : undefined;
    const end = state.useEndDate && state.endDate != null ? EndOfDay(state.endDate!) : undefined;
    const statuses = state.statuses.length > 0 ? state.statuses : undefined;


    dispatch({type: JobsActions.loadingMoreData, payload: true});
    service.GetUsageDetail(
      statuses,
      customerIds,
      userIds,
      jobIds,
      start ?? undefined,
      end ?? undefined,
      state.sortingField,
      state.sortAscending,
      paginationToken,
      pageSize)
      .then(
        data => {
          paginationToken = data.isDone ? undefined : data.paginationData!.paginationToken;

          if (!data.isDone && data.entries!.length === 0) {
            GetNextSet();
          } else {
            data.entries!.forEach(e => {
              state.dataItems.push(e);
            });

            dispatch({
              type: JobsActions.multipleActions, payload: {
                dataItems: [...state.dataItems],
                loadingMoreData: false,
                hasMoreData: !data.isDone,
              }
            });
          }
        }
      )
      .catch(er => {
        onError(er, 'Filter Data');
        dispatch({type: JobsActions.loading, payload: false});
      });
  }

  function checkChanged(newState: CheckboxState, box: string): void {
    let action: JobsActions;
    switch (box) {
      case 'start':
        action = JobsActions.useStartDate;
        break;
      case 'end':
        action = JobsActions.useEndDate;
        break;
      case 'sort':
        action = JobsActions.sortAscending;
        break;
      default:
        return;
    }
    dispatch({type: action, payload: newState === true});
  }

  function onDateChange(newDate: Date | null | undefined, isStart: boolean): void {
    const action = isStart ? JobsActions.startDate : JobsActions.endDate;
    dispatch({type: action, payload: newDate});
  }

  function onListChange(ids: string[], listType: string): void {
    let action: JobsActions;
    switch (listType) {
      case 'customer':
        action = JobsActions.customerIds;
        break;
      case 'user':
        action = JobsActions.userIds;
        break;
      case 'job':
        action = JobsActions.jobIds;
        break;
      default:
        return;
    }
    dispatch({type: action, payload: ids})
  }

  function usageSortChange(sort: UsageSortType): void {
    dispatch({type: JobsActions.sortingField, payload: sort});
  }

  function onStatusChange(e: { label: string, value: string }[] | undefined): void {
    const statuses = e == null ? [] : e.map(x => JobRunItemStatusType[x.value as keyof typeof JobRunItemStatusType]);
    dispatch({type: JobsActions.statuses, payload: statuses});
  }

  function onError(error: any, operation: string): void {
    alert(GetErrorMessage(error, operation));
  }

  const sortOptions = [
    {value: UsageSortType.Default, label: 'Default'},
    {value: UsageSortType.Date, label: 'Date'}
  ];

  const selectedOption = sortOptions.find(o => o.value === state.sortingField);
  const statusOptions: { label: string, value: string }[] = useMemo(() => {
    const options: { label: string, value: string }[] = [];
    Object.keys(JobRunItemStatusType).map(k => options.push({label: k, value: k}));
    return options;
  }, []);

  const {controlProps, menuProps} = useMultiDropdown({
    items: statusOptions,
    onSelectedItemsChange: e => onStatusChange(e.selectedItems)
  });

  return (
    <ContentWrapper>
      <FlexRow style={{flex: 0}}>
        <h1 style={Theme.typography.heading1}>Jobs</h1>
      </FlexRow>
      <FlexColumn>
        <FlexRow style={{flex: 0}}>
          <ColumnLeft style={Theme.typography.bodyMedium}>Customer Ids</ColumnLeft>
          <ColumnRight style={Theme.typography.bodyMedium}>
            <IdInput ids={state.customerIds} onChange={ids => onListChange(ids, 'customer')} onCommit={getData}/>
          </ColumnRight>
        </FlexRow>
        <FlexRow style={{flex: 0}}>
          <ColumnLeft style={Theme.typography.bodyMedium}>User Ids</ColumnLeft>
          <ColumnRight style={Theme.typography.bodyMedium}>
            <IdInput ids={state.userIds} onChange={ids => onListChange(ids, 'user')} onCommit={getData}/>
          </ColumnRight>
        </FlexRow>
        <FlexRow style={{flex: 0}}>
          <ColumnLeft style={Theme.typography.bodyMedium}>Job Ids</ColumnLeft>
          <ColumnRight style={Theme.typography.bodyMedium}>
            <IdInput ids={state.jobIds} onChange={ids => onListChange(ids, 'job')} onCommit={getData}/>
          </ColumnRight>
        </FlexRow>
        <FlexRow style={{flex: 0}}>
          <ColumnLeft style={Theme.typography.bodyMedium}>Statuses</ColumnLeft>
          <ColumnRight style={Theme.typography.bodyMedium}>
            <div style={{width: '100%'}}>
              <MultiDropdownTokenControl {...controlProps}/>
              <DropdownMenu {...menuProps}/>
            </div>
          </ColumnRight>
        </FlexRow>
        <FlexRow style={{flex: 0}}>
          <ColumnLeft style={Theme.typography.bodyMedium}>
            <FlexRowCentered>
              <Checkbox
                checked={state.useStartDate}
                onChange={value => checkChanged(value, 'start')}/>
              <label style={{marginLeft: '0.5em'}}>Start Date</label>
            </FlexRowCentered>
          </ColumnLeft>
          <ColumnRight style={Theme.typography.bodyMedium}>
            <DatePicker
              value={state.startDate}
              disabled={!state.useStartDate}
              onChange={value => onDateChange(value.date, true)}/>
          </ColumnRight>
        </FlexRow>
        <FlexRow style={{flex: 0}}>
          <ColumnLeft style={Theme.typography.bodyMedium}>
            <FlexRowCentered>
              <Checkbox
                checked={state.useEndDate}
                onChange={value => checkChanged(value, 'end')}/>
              <label style={{marginLeft: '0.5em'}}>End Date</label>
            </FlexRowCentered>
          </ColumnLeft>
          <ColumnRight style={Theme.typography.bodyMedium}>
            <DatePicker
              value={state.endDate}
              disabled={!state.useEndDate}
              onChange={value => onDateChange(value.date, false)}/>
          </ColumnRight>
        </FlexRow>
        <FlexRow style={{flex: 0}}>
          <ColumnLeft style={Theme.typography.bodyMedium}>Sorting</ColumnLeft>
          <ColumnRight style={Theme.typography.bodyMedium}>
            <FlexRow>
              <Selector selected={selectedOption}
                        items={sortOptions}
                        onSelectionChange={s => usageSortChange(s?.value as UsageSortType ?? UsageSortType.Date)}/>
              <FlexRowCentered>
                <Checkbox
                  checked={state.sortAscending}
                  style={{marginLeft: '1em'}}
                  onChange={value => checkChanged(value, 'sort')}/>
                <label style={{marginLeft: '0.5em'}}>Ascending</label>
              </FlexRowCentered>
            </FlexRow>
          </ColumnRight>
        </FlexRow>
        <FlexRowCentered style={{marginBottom: '2em'}}>
          <BlueButton onClick={getData}>
            <FlexRowCentered>
              <ArrowRotateTwoIcon style={{marginRight: '0.5em'}}/>
              <span style={Theme.typography.labelMedium}>Refresh Data</span>
            </FlexRowCentered>
          </BlueButton>
          <BlueButton style={{marginLeft: '1em'}}
                      onClick={downloadRaw}
                      disabled={state.customerIds.length === 0 && state.userIds.length === 0 && state.jobIds.length === 0 && state.statuses.length === 0
                        ? (!state.useStartDate && !state.useEndDate) || (state.useStartDate && state.startDate == null) || (state.useEndDate && state.endDate == null)
                        : (state.useStartDate && state.startDate == null) || (state.useEndDate && state.endDate == null)}>
            <FlexRowCentered>
              {state.loadingCsv && <ProgressRing size={'small'} style={{marginRight: '0.5em'}}/>}
              {!state.loadingCsv && <CloudDownArrowIcon style={{marginRight: '0.5em'}}/>}
              <span style={Theme.typography.labelMedium}>Download Raw (csv) Data</span>
            </FlexRowCentered>
          </BlueButton>
        </FlexRowCentered>
        <FlexFill>
          {
            !state.loading && state.dataItems.length > 0 &&
            <Table<DeckardJobDataEntry>
              style={state.dataItems.length === 0 ? {height: 'inherit'} : {}}
              columns={[
                {
                  id: 'date',
                  accessorFn: row => row.jobRunDataEntry?.jobRunScheduledStartTime,
                  header: () => 'Date',
                  cell: d =>
                    d.row.original.jobRunDataEntry?.jobRunScheduledStartTime == null
                      ? null
                      : ConvertRunDate.Convert(d.row.original.jobRunDataEntry.jobRunScheduledStartTime)
                },
                {
                  id: 'jobId',
                  accessorFn: row => row.jobRunDataEntry?.jobId,
                  header: () => 'Job ID',
                },
                {
                  id: 'jobName',
                  accessorFn: row => row.jobRunDataEntry?.jobName,
                  header: () => 'Job Name',
                },
                {
                  id: 'runStatus',
                  accessorFn: row => row.jobRunDataEntry?.runStatus,
                  header: () => 'Run Status',
                },
                {
                  id: 'jobStatus',
                  accessorFn: row => row.jobRunDataEntry?.jobStatus,
                  header: () => 'Job Status',
                },
                {
                  id: 'modelStatus',
                  accessorFn: row => row.modelStatus,
                  header: () => 'Item Status',
                },
                {
                  id: 'user',
                  accessorFn: row => row.jobRunDataEntry?.username,
                  header: () => 'User',
                  cell: d => {
                    const job = d.row.original as DeckardJobDataEntry;
                    return `${job.jobRunDataEntry?.firstName} ${job.jobRunDataEntry?.lastName} (${job.jobRunDataEntry?.username})`;
                  }
                },
                {
                  id: 'details',
                  cell: d => {
                    const job = d.row.original as DeckardJobDataEntry;
                    return <BlueButton onClick={() => {
                      dispatch({type: JobsActions.multipleActions, payload: {showDetail: true, detailObject: job}});
                    }}>Details</BlueButton>;
                  }
                },
              ]}
              data={state.dataItems}
              renderLastRow={() => state.hasMoreData &&
                <LoadMoreDataRow isLoading={state.loadingMoreData} onLoad={async () => GetNextSet()}/>}/>
          }
          {
            state.loading &&
            <CenteringContainer>
              <ProgressRing size={'large'}/>
            </CenteringContainer>
          }
          {
            !state.loading && state.dataItems.length === 0 &&
            <CenteringContainer style={{flexDirection: 'column'}}>
              <Illustration type={'pagesTextGrey'} height={200} width={200}/>
              <p style={Theme.typography.bodyLarge}>No Job Data to Display</p>
            </CenteringContainer>
          }
        </FlexFill>
      </FlexColumn>
      <Modal open={state.showDetail}>
        <Modal.Header>Item Detail</Modal.Header>
        <Modal.Body>
          {
            state.detailObject != null &&
            <ObjectPropertyDetail detailObject={state.detailObject} truncateValues={state.truncateDetailStrings}/>
          }
        </Modal.Body>
        <Modal.Footer>
          <FlexRow>
            <BlueButton style={{marginRight: '1em'}}
                        onClick={() => dispatch({
                          type: JobsActions.truncateDetailStrings,
                          payload: !state.truncateDetailStrings
                        })}>
              <FlexRowCentered>
                <span
                  style={Theme.typography.labelMedium}>{state.truncateDetailStrings ? 'Show Full Values' : 'Truncate Values'}</span>
              </FlexRowCentered>
            </BlueButton>
            <FlexFill/>
            <BlueButton style={{marginLeft: '1em'}}
                        onClick={() => dispatch({type: JobsActions.showDetail, payload: false})}>
              <FlexRowCentered>
                <span style={Theme.typography.labelMedium}>Close</span>
              </FlexRowCentered>
            </BlueButton>
          </FlexRow>
        </Modal.Footer>
      </Modal>
    </ContentWrapper>
  );
};

export default Jobs;
