////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import { TaskEditState } from "../states/TaskEditState";
import { TaskEditActions } from "../../Enums";

export function reducer(
  state: TaskEditState,
  action: { type: TaskEditActions, payload: any }
): TaskEditState {
  switch (action.type) {
    case TaskEditActions.originalTask:
      return { ...state, OriginalTask: action.payload };
    case TaskEditActions.selectedProjectSource:
      return { ...state, selectedProjectSource: action.payload };
    case TaskEditActions.selectedProjectDestination:
      return { ...state, selectedProjectDestination: action.payload };
    case TaskEditActions.expandedSource:
      return { ...state, expandedSource: action.payload };
    case TaskEditActions.expandedDestination:
      return { ...state, expandedDestination: action.payload };
    case TaskEditActions.showCancelConfirm:
      return { ...state, showCancelConfirm: action.payload };
    case TaskEditActions.Saving:
      return { ...state, Saving: action.payload };
    case TaskEditActions.loadingProjects:
      return { ...state, LoadingProjects: action.payload };
    case TaskEditActions.projects:
      return { ...state, Projects: action.payload };
    case TaskEditActions.selectedTab:
      return { ...state, SelectedTab: action.payload };
    case TaskEditActions.new:
      return { ...state, IsNewTask: action.payload };
    case TaskEditActions.duplicating:
      return { ...state, IsDuplicating: action.payload };
    case TaskEditActions.loading:
      return { ...state, Loading: action.payload };
    case TaskEditActions.multipleActions:
      return { ...state, ...action.payload };
    case TaskEditActions.task:
      return { ...state, Task: action.payload };
    case TaskEditActions.projectWiseConfigs:
      return { ...state, ProjectWiseConfigs: action.payload };
    case TaskEditActions.loadingProjectWiseConfigs:
      return { ...state, LoadingProjectWiseConfigs: action.payload };
    case TaskEditActions.selectedProjectWiseConfigSource:
      return { ...state, selectedProjectWiseConfigSource: action.payload };
    case TaskEditActions.selectedProjectWiseConfigDestination:
      return { ...state, selectedProjectWiseConfigDestination: action.payload };
    case TaskEditActions.selectedTabSource:
      return { ...state, selectedTabSource: action.payload };
    case TaskEditActions.selectedTabDestination:
      return { ...state, selectedTabDestination: action.payload };
    case TaskEditActions.disableSave:
      return { ...state, DisableSave: action.payload };
    case TaskEditActions.saveTooltip:
      return { ...state, SaveTooltip: action.payload };
  }
}